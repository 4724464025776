import { FetchResponse } from "@lib/common/response";
import {
  PipelineListResponse,
  useProjectPipelines,
  useRestartPipelineRequest,
} from "@lib/pipelines";
import { Project } from "@lib/projects";
import { useNavigate } from "react-router-dom";
import ListItem from "./ListItem";

import "./List.css";

const PipelinesList = ({ project }: { project: Project }) => {
  const pipelinesResponse = useProjectPipelines(project.id);
  const restartPipeline = useRestartPipelineRequest();
  const navigate = useNavigate();
  const viewDeployment = (id: string) => {
    navigate(`/pipelines/${id}`);
  }


  return (
    <PipelinesListUI
      data={pipelinesResponse.data}
      project={project}
      isLoading={pipelinesResponse.isLoading}
      error={pipelinesResponse.error}
      restart={restartPipeline}
      onClick={viewDeployment}
    />
  );
};

const PipelinesListUI = ({
  data: pipelines,
  project,
  isLoading,
  error,
  restart,
  onClick,
}: FetchResponse<PipelineListResponse> & {
  project: Project;
  restart: (project: string, deploymentId: string) => Promise<void>;
  onClick: (id: string) => void;
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!pipelines?.pipelines) {
    return <div>No deployments found</div>;
  }

  return (
    <div className="pipeline-list">
      {pipelines?.pipelines.map((pipeline) => (
        <ListItem
          key={pipeline.id}
          project={project}
          deployment={pipeline}
          restart={restart}
          onClick={() => onClick(pipeline.id)}
        />
      ))}
    </div>
  );
};

export { PipelinesList as DeploymentsList };
export default PipelinesListUI;
