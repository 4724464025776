import { FetchResponse } from "@lib/common/response";
import { Pipeline as Model, usePipeline } from "@lib/pipelines";
import ActionUI from "./Action";

const Pipeline = ({ id }: { id: string }) => {
  const pipeline = usePipeline(id);
  return (
    <PipelineView
      data={pipeline.data}
      isLoading={pipeline.isLoading}
      error={pipeline.error}
    />
  );
};

const PipelineView = ({
  data: pipeline,
  isLoading,
  error,
}: FetchResponse<Model>) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!pipeline) {
    return <div>No pipeline found</div>;
  }

  const buildStage = pipeline.actions?.find((action) => action.action === "Build");
  const deployStage = pipeline.actions?.find((action) => action.action === "Deploy");

  return (
    <div>
      {buildStage && (
        <ActionUI action={buildStage} deployment={pipeline} />
      )}
      {deployStage && (
        <ActionUI action={deployStage} deployment={pipeline} />
      )}
    </div>
  );
};

export default Pipeline;
