import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";
export * from "./platformProviders";

export type Provider = {
  id: string;
  name: string;
  slug: string;
  type: string;
  details: { [key: string]: string };
};

export type ProviderDetail = {
  name: string;
  description: string;
  type: string;
  imageUri: string;
  config: {
    capabilities: string[];
    fields: { [key: string]: ProviderFieldConfig };
  };
};

export type ProviderFieldConfig = {
  order: string;
  description: string;
  type: string;
  isEncrypted: string;
};

export type GetProvidersResponse = { providers: Provider[] };
export const useProviders = (): FetchResponse<GetProvidersResponse> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<GetProvidersResponse>(
    "/providers",
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve providers, try again later`);
      },
    })
  );
};

export const useProvider = (slug: string): FetchResponse<Provider> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<Provider>(
    `/providers/${slug}`,
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve provider ${slug}, try again later`);
      },
    })
  );
};

export type GetProviderDetailsResponse =
  | { provider: ProviderDetail }
  | undefined;
export const useProviderDetails = (): {
  data: GetProviderDetailsResponse;
  error: any;
  isLoading: boolean;
} => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<GetProviderDetailsResponse>(
    "/providers/types",
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve provider details, try again later`);
      },
    })
  );
};

export type GetProviderDetailResponse = ProviderDetail | undefined;
export const useProviderDetail = (
  type: string
): {
  data: GetProviderDetailResponse;
  error: any;
  isLoading: boolean;
} => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<GetProviderDetailResponse>(
    `/providers/types/${type}`,
    fetcher({
      onNotOk: () => {
        addError(
          `Unable to retrieve provider details for type ${type}, try again later`
        );
      },
    })
  );
};

export type CreateProviderRequest = {
  name: string;
  slug: string;
  type: string;
  details: Record<string, string>;
  capabilities: string[];
};
export const useCreateProvider = (): ((
  provider: CreateProviderRequest
) => Promise<void>) => {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();

  return (provider: CreateProviderRequest) =>
    fetcher({
      onNotOk: () => {
        addError(`Unable to create provider, try again later`);
      },
    })("/providers", {
      method: "POST",
      body: JSON.stringify(provider),
    }).then(() => {
      mutate("/providers");
    });
};
