import { useErrors } from "@contexts/ErrorsContext";

const ErrorsList = () => {
  const { errors, clearError } = useErrors();

  return <ErrorsListView errors={errors} clearError={clearError} />;
};

const ErrorsListView = ({
  errors,
  clearError,
}: {
  errors: string[];
  clearError: (idx: number) => void;
}) => {
  return (
    <div className="has-background-danger">
      {errors.map((error, idx) => (
        <div className="container my-2" key={`errors-${idx}`}>
          <div className="columns">
            <div className="column has-text-weight-bold has-text-black">{error}</div>
            <div className="column is-1">
              <button
                className="delete"
                onClick={() => clearError(idx)}
              ></button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ErrorsList;
