import { useNavigate, useParams } from "react-router-dom";
import { BackAction } from "@components/navigation/BackAction";
import { List } from "@components/projects/List";

const SourcesPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  return (
    <>
      <header className="page-header mb-2" onClick={goBack}>
        <BackAction text="Settings" />
      </header>
      <List source={id!} />
    </>
  );
};

export default SourcesPage;
