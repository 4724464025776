import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";

export type Environment = {
  id: string;
  name: string;
  slug: string;
};

export type GetEnvironmentsResponse = { environments: Environment[] };
export const useEnvironments = ( ): FetchResponse<GetEnvironmentsResponse> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return useSWR<GetEnvironmentsResponse>(
    `/environments`,
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve environments, try again later`);
      },
    })
  );
};

export const useEnvironment = (
  slug: string
): FetchResponse<Environment> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return useSWR<Environment>(
    `/environments/${slug}`,
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve environment ${slug}, try again later`);
      },
    })
  );
};

export const useDeleteEnvironment = async (
  slug: string
): Promise<void> => {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();
  
  await fetcher({
    onNotOk: () => {
      addError(`Unable to delete environment ${slug}, try again later`);
    },
  })(`/environments/${slug}`, {
    method: "DELETE",
  });

  mutate(`/environments`);
};

export interface CreateEnvironmentRequest {
  name: string;
}

export function useCreateEnvironment(): (
  platform: string,
  envirionment: CreateEnvironmentRequest
) => Promise<void> {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();
  
  return (platform: string, environment: CreateEnvironmentRequest) => fetcher({
    onNotOk: () => {
      addError(`Unable to create environment, try again later`);
    },
  })(`/platforms/${platform}/environments`, {
    method: "POST",
    body: JSON.stringify(environment),
  }).then((response) => {
    mutate(`/platforms/${platform}/environments`);
    return response;
  });
}