import Menu from "@components/settings/Menu";
import { Outlet, useNavigate } from "react-router-dom";

export const Layout = () => {
  const navigate = useNavigate();

  return <LayoutUI onBack={() => navigate(-1)} />;
};

const LayoutUI = ({ onBack = () => {} }: { onBack?: () => void }) => {
  return (
    <div className="container">
      <div className="columns pt-2">
        <div className="column is-one-quarter">
          <h1 className="title">Settings</h1>
        </div>
        <div className="column">
          <div className="buttons is-pulled-right">
            <button className="button is-light" onClick={onBack}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-quarter">
          <Menu />
        </div>
        <div className="column">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutUI;
