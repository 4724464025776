import Button from "@components/ui/Button";
import { Project, ProjectDetails, useSetProjectRulesRequest } from "@lib/projects";
import { useFieldArray, useForm } from "react-hook-form";
import { DetailRule } from "./DetailRule";

const Details = ({ project }: { project: Project }) => {
  const saveDetails = useSetProjectRulesRequest();

  return <DetailsUI project={project} onSave={(details) => saveDetails(project.id, details.rules)}/>;
};

const DetailsUI = ({
  project,
  onSave = () => Promise.resolve(),
}: {
  project: Project;
  onSave?: (details: ProjectDetails) => Promise<void>;
}) => {
  const {
    register,
    getValues,
    control,
    formState: { isDirty },
    reset
  } = useForm({ values: project.details });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rules",
  });

  return (
    <div className="project-details">
      {fields.map((field, index) => (
        <DetailRule
          remove={remove}
          key={field.id}
          idx={index}
          control={control}
          />
      ))}
      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <Button onClick={() => append({ actions: [{type: 'deploy', environment: ''}], conditions: [{ type: 'push', pattern: ''}]})}>Add Rule</Button>
        </div>
        <div className="control">
          <Button disabled={!isDirty} onClick={() => reset()}>
            Reset
          </Button>
        </div>
        <div className="control">
          <Button disabled={!isDirty} primary={true} onClick={() => onSave(getValues())}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export { Details };
export default DetailsUI;
