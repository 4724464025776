import { ProjectDetails } from "@lib/projects";
import { UseFormRegister } from "react-hook-form";

const DetailRuleCondition = ({
  ruleIdx,
  idx,
  register,
}: {
  ruleIdx: number;
  idx: number;
  register?: UseFormRegister<ProjectDetails>;
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">On Push to </label>
      </div>
      <div className="field-body">
        <div className="field" >
            <div className="control">
            <input
                className="input"
                type="text"
                {...register?.(`rules.${ruleIdx}.conditions.${idx}.pattern`)}
            />
            </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRuleCondition;
