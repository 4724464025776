import CreateOrganization from "./steps/CreateOrganization";
import LinkSource from "./steps/LinkSource";
import LinkProvider from "./steps/LinkProvider";
import OnboardingProvider, {
  useOnboarding,
} from "@contexts/OnboardingContext";
import Done from "./steps/Done";
import CreateProjects from "./steps/CreateProjects";
import CreateEnvironment from "./steps/CreateEnvirionment";

const Onboarding = () => {
  return (
    <OnboardingProvider>
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-half">
            <OnboardingProgress />
            <OnboardingStep />
          </div>
        </div>
      </div>
    </OnboardingProvider>
  );
};

const OnboardingStep = () => {
  const { step } = useOnboarding();

  switch (step) {
    case 0:
      return <CreateOrganization />;
    case 1:
      return <CreateEnvironment />;
    case 2:
      return <LinkSource />;
    case 3:
      return <CreateProjects />;
    case 4:
      return <LinkProvider />;
    case 5:
      return <Done />;
    default:
      return <></>;
  }
};

const OnboardingProgress = () => {
  const { step } = useOnboarding();

  return <progress className="progress is-primary" value={step + 1} max="6" />;
};

export default Onboarding;
