import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";

export type Pipeline = {
  id: string;
  createdAt: Date;
  startedAt?: Date;
  updatedAt: Date;
  project: string;
  status: string;
  details: {
    ref: string;
    commit: string;
  };
  actions?: PipelineAction[];
};

export type PipelineAction = {
  id: string;
  action: string;
  steps?: PipelineActionStep[];
};

export type PipelineActionStep = {
  name: string;
  status: string;
  createdAt: Date;
  startedAt?: Date;
  updatedAt: Date;
  details: {
    logLocation: string;
  };
};

export type PipelineListResponse = { pipelines: Pipeline[] };
export const useProjectPipelines = (
  project: string
): FetchResponse<PipelineListResponse> => {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();

  return useSWR<PipelineListResponse>(
    `/projects/${project}/pipelines`,
    fetcher({
      onNotOk: () => {
        addError("Unable to retrieve pipelines, try again later");
      },
      postOp: (data: PipelineListResponse) => ({
        pipelines: data.pipelines.map(deploymentCleanup),
      }),
    }),
    {
      refreshInterval: 1000,
    }
  );
};

export const usePipeline = (id: string): FetchResponse<Pipeline> => {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();

  return useSWR<Pipeline>(
    `/pipelines/${id}`,
    fetcher({
      onNotOk: () => {
        addError("Unable to retrieve pipeline, try again later");
      },
      postOp: deploymentCleanup,
    }),
    {
      refreshInterval: 1000,
    }
  );
};

export const useCreatePipelineRequest = (): ((
  project: string,
  environment: string,
  details: { [key: string]: any }
) => Promise<void>) => {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();

  return (
    project: string,
    environment: string,
    details: { [key: string]: any }
  ) =>
    fetcher({
      onNotOk: (response: Response) => {
        addError("Unable to create pipeline, try again later");
      },
    })(`/projects/${project}/pipelines`, {
      method: "POST",
      body: JSON.stringify({ environment, details }),
    }).then(() => {
      mutate(`/projects/${project}/pipelines`);
    });
};

export const useRestartPipelineRequest = (): ((
  project: string,
  pipelineId: string
) => Promise<void>) => {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();

  return (project: string, pipelineId: string) =>
    fetcher({
      onNotOk: (response: Response) => {
        addError("Unable to restart pipeline, try again later");
      },
    })(`/pipelines/${pipelineId}/restart`, {
      method: "POST",
    }).then(() => {
      mutate(`/projects/${project}/pipelines`);
      mutate(`/pipelines/${pipelineId}`);
    });
};

const deploymentCleanup = (deployment: Pipeline) => {
  return {
    ...deployment,
    createdAt: new Date(deployment.createdAt),
    startedAt: deployment.startedAt ? new Date(deployment.startedAt) : undefined,
    updatedAt: new Date(deployment.updatedAt),
    actions: deployment.actions?.map((action) => ({
      ...action,
      steps: action.steps?.map((step) => ({
        ...step,
        createdAt: new Date(step.createdAt),
        startedAt: step.startedAt ? new Date(step.startedAt) : undefined,
        updatedAt: new Date(step.updatedAt),
      })),
    })),
  };
};
