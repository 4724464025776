import { Pipeline, PipelineAction, PipelineActionStep } from "@lib/pipelines";
import TimerUI, { Timer } from "@components/ui/Timer";
import { useState } from "react";
import { LogViewer } from "./LogViewer";

import "./Step.css";
import CardListItemUI from "@components/ui/CardListItem";

const Step = ({
  deployment,
  action,
  step,
}: {
  deployment: Pipeline;
  action: PipelineAction;
  step: PipelineActionStep;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StepUI
      step={step}
      deploymentId={deployment.id}
      actionId={action.id}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
    />
  );
};

const StepUI = ({
  step,
  isOpen = false,
  deploymentId,
  actionId,
  onClick = () => {},
}: {
  step: PipelineActionStep;
  deploymentId: string;
  actionId: string;
  isOpen: boolean;
  onClick?: (event: any) => void;
}) => {
  const stepClass = {
    "deployment-step": true,
    "is-success": step.status === "Completed",
    "has-background-warning": step.status === "Pending",
    "is-failed": step.status === "Failed",
    "has-background-info": step.status === "InProgress",
  };
  const hasLogs = step.details.logLocation !== "";
  const headerContent = (<>
    {(step.status === "Completed" || step.status === "Failed") && (
        <TimerUI
          timer={step.updatedAt!.getTime() - step.createdAt.getTime()}
        />
      )}
      {step.status === "InProgress" && <Timer startTime={step.startedAt!} />}
      {step.status === "Pending" && <Timer startTime={step.createdAt} />}
    </>)

  return (
    <CardListItemUI
      title={step.name}
      isOpen={isOpen}
      hasContent={hasLogs}
      classes={stepClass}
      onClick={onClick}
      headerContent={headerContent}
    >
      <LogViewer pipelineId={deploymentId} actionId={actionId} ply={step.name} />
    </CardListItemUI>
  );
};

export { Step };
export default StepUI;
