import { DomainListResponse, useCreateDomainRequest, useDomains } from "@lib/domains";
import SettingBase from "./Base";
import { FetchResponse } from "@lib/common/response";
import { useState } from "react";

const DomainList = () => {
  const { data, isLoading, error } = useDomains();

  return (
    <DomainListView
      data={data}
      isLoading={isLoading}
      error={error}
    />
  )
}

const DomainListView = ({
  data,
  isLoading,
  error,
}: FetchResponse<DomainListResponse>) => {
  return (
    <SettingBase title="DNS Settings" id="dns">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Domain Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { data?.domains.map((entry) => (
            <tr>
              <td>{entry.name}</td>
              <td>{entry.status}</td>
              <td>
                <button className="button is-danger">
                  Remove
                </button>
              </td>
            </tr>
          ))
          }
        </tbody>
      </table>
      <hr />
      <CreateDomainForm />
    </SettingBase>
  );
};

const CreateDomainForm = () => {
  const createDomain = useCreateDomainRequest();
  const [ domainName, setDomainName ] = useState("");

  const onChange = (event: any) => {
    setDomainName(event.currentTarget.value);
  };

  const create = () => {
    createDomain({ name: domainName });
  };

  return (
    <div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            Add Domain Name
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <input className="input" type="text" placeholder="Domain Name" onChange={onChange}/>
          </div>
          <div className="field">
            <button className="button is-primary" onClick={create}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainList;