import { createContext, useContext, useState } from "react";

type ErrorsContent = {
  errors: string[];
  addError: (error: string) => void;
  clearError: (idx: number) => void;
};

export const ErrorsContext = createContext<ErrorsContent>({
  errors: [],
  addError: () => {},
  clearError: () => {},
});

const ErrorsProvider = ({ children }: { children: React.ReactNode }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const context = {
    errors,
    addError: (error: string) => setErrors([...errors, error]),
    clearError: (idx: number) => setErrors(errors.filter((_, i) => i !== idx)),
  };

  return (
    <ErrorsContext.Provider value={context}>{children}</ErrorsContext.Provider>
  );
};

export const useErrors = () => useContext(ErrorsContext);

export default ErrorsProvider;
