import { useEffect, useState } from "react";

import "./Menu.scss"

export type MenuEntry =
  | { category: string; entries: { label: string; value: string }[] }
  | { label: string; value: string };

function isCategory(
  entry: MenuEntry
): entry is { category: string; entries: { label: string; value: string }[] } {
  return (
    (entry as { category: string; entries: { label: string; value: string }[] })
      .category !== undefined
  );
}

const Menu = ({
  entries,
  onSelected = async () => {},
}: {
  entries: MenuEntry[];
  onSelected?: (value: string) => Promise<void>;
}) => {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    onSelected(selected);
  }, [onSelected, selected]);

  return (
    <aside className="menu">
      {entries.map((entry) =>
        isCategory(entry) ? (
          <>
            <p className="menu-label">{entry.category}</p>
            <ul className="menu-list">
              {entry.entries.map((entry) => (
                <li key={`entry-${entry.value}`}>
                  <a
                    className={selected === entry.value ? "is-active" : ""}
                    onClick={() => {
                      setSelected(entry.value);
                    }}
                  >
                    {entry.label}
                  </a>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <ul className="menu-list">
              <li key={`entry-${entry.value}`}>
                <a
                  className={selected === entry.value ? "is-active" : ""}
                  onClick={() => {
                    setSelected(entry.value);
                  }}
                >
                  {entry.label}
                </a>
              </li>
            </ul>
          </>
        )
      )}
    </aside>
  );
};

export default Menu;
