import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";

export type Domain = {
  name: string;
  status: string;
  details: { [key: string]: string };
};

export type DomainListResponse = { domains: Domain[] };
export const useDomains = (): FetchResponse<DomainListResponse> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<DomainListResponse>(
    "/domains",
    fetcher({
      onNotOk: () => {
        addError("Unable to retrieve domains, try again later");
      },
    })
  );
};

export const useDomain = (name: string): FetchResponse<Domain> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<Domain>(
    `/domains/${name}`,
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve domain ${name}, try again later`);
      },
    })
  );
};

export type CreateDomainRequest = {
  name: string;
};

export function useCreateDomainRequest(): (
  request: CreateDomainRequest
) => Promise<void> {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();

  return (request: CreateDomainRequest) =>
    fetcher({
        onNotOk: (response: Response) => {
            addError("Unable to create deployment, try again later");
        }
    })(`/domains`, {
      method: "POST",
      body: JSON.stringify(request),
    })
      .then(() => {
        mutate("/domains");
      });
}
