import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Done = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 2000);
  }, [navigate]);

  return (
    <div>
      <h1>Done</h1>
      <span>Redirecting...</span>
    </div>
  );
};

export default Done;
