import CardListItemUI from "@components/ui/CardListItem";
import { Provider } from "@lib/providers";

const ListItemUI = ({
  provider,
  onClick = () => {},
}: {
  provider: Provider;
  onClick?: () => void;
}) => {
  return (
    <CardListItemUI
      title={`${provider.type} - ${provider.name}`}
      hasContent={false}
      onClick={onClick}
    />
  );
};

export default ListItemUI;
