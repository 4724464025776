import ErrorsProvider from "@contexts/ErrorsContext";
import GlobalProvider from "@contexts/GlobalContext";
import ModalProvider from "@contexts/ModalContext";
import Router from "navigation";
import ErrorsList from "./errors/ErrorsList";

function App() {
  return (
    <div>
      <ErrorsProvider>
        <ErrorsList />
        <GlobalProvider>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </GlobalProvider>
      </ErrorsProvider>
    </div>
  );
}

export default App;
