import { Pipeline, PipelineAction } from "@lib/pipelines";
import { Step } from "./Step";

const ActionUI = ({
  action,
  deployment,
}: {
  action: PipelineAction;
  deployment: Pipeline;
}) => {
  return (
    <div className="pipeline-action">
      <div className="is-size-3">{action.action}</div>
      <div className="pipeline-action-steps">
        {action.steps?.map((step) => (
          <Step key={step.name} deployment={deployment} action={action} step={step} />
        ))}
      </div>
    </div>
  );
};

export default ActionUI
