import { List } from "@components/providers/List";
import SettingBase from "./Base";

const Providers = () => {
  return (
    <SettingBase id="providers" title="Providers">
      <List />
    </SettingBase>
  )
}

export default Providers;