import { faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import "./CardListItem.css";

export interface CardListItemProps {
  title: string;
  isOpen?: boolean;
  hasContent?: boolean;
  classes?: { [key: string]: boolean };
  onClick?: (event: any) => void;
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
}

const CardListItemUI = ({
  title,
  isOpen = false,
  hasContent = false,
  classes = {},
  onClick,
  children,
  headerContent,
}: CardListItemProps) => {
  const icon = isOpen ? faAngleLeft : faAngleDown;
  const itemClass = classNames("card", "mb-2", classes);
  const headerClass = classNames("card-header", {
    "is-clickable": onClick && hasContent || false,
  });

  return (
    <div className={itemClass}>
      <header className={headerClass} onClick={onClick}>
        <p className="card-header-title">{title}</p>
        <div className="card-header-icon" aria-label="more options">
          {headerContent}
          {hasContent && (
            <span className="icon">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </div>
      </header>
      {isOpen && hasContent && (
        <div className="card-content">
          {children}
        </div>
      )}
    </div>
  );
};

export default CardListItemUI;
