import SettingBase from "./Base";
import { List } from "@components/sources/List";

const Sources = () => {
  return (
    <SettingBase title="Sources" id="sources">
      <List />
    </SettingBase>
  );
};

export default Sources;
