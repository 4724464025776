import { useOnboarding } from "@contexts/OnboardingContext";
import { useSources } from "@lib/sources";
import BaseStep from "./Base";
import { List } from "@components/projects/List";

const CreateProjects = () => {
  const { nextStep } = useOnboarding();
  const { data: sources, isLoading, error } = useSources();
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseStep
      title="Create Projects"
      nextStep={nextStep}
      actionText="Next"
      loading={false}
    >
      <List source={sources!.sources[0].id} shallow={true}/>
    </BaseStep>
  );
};

export default CreateProjects;
