import { settings } from "@components/settings/Menu";

const SettingsPage = () => {
  return (
    <>
      {settings.map((category) => (
        <>
          {category.entries.map((entry) => (
            <>{entry.component}</>
          ))}
        </>
      ))}
    </>
  );
};

export default SettingsPage;
