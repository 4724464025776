import { useParams } from "react-router-dom";
import { usePlatform } from "@lib/platform";

const Platform = () => {
  const { slug } = useParams();
  const { data: platform, isLoading, error } = usePlatform(slug!);

  if (isLoading) {
    return <h1 className="is-1">Loading...</h1>;
  }

  return <h1 className="is-1">{platform!.name}</h1>;
};

export default Platform;
