import { useEffect, useState } from "react";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Timer = ({
    startTime
}: {
    startTime: Date;
}) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(Date.now() - startTime.getTime());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [startTime]);

    return (
        <TimerUI timer={timer} />
    );
}

const TimerUI = ({
    timer
}: { timer: number }) => {
    const hours = Math.floor(timer / 3600000).toString();
    const minutes = Math.floor(timer / 60000 % 60).toString().padStart(2, "0");
    const seconds = Math.floor((timer / 1000) % 60).toString().padStart(2, "0");

    return (
        <span>
            <FontAwesomeIcon icon={faStopwatch} className="mr-1"/>
            {hours !== "0" ? `${hours}:` : ''}{minutes}:{seconds}
        </span>
    );
}

export { Timer };
export default TimerUI;