import { FetchResponse } from "@lib/common/response";
import {
  CreateProjectRequest,
  Project,
  ProjectListResponse,
  useCreateProjectRequest,
  useSetEnabledProjectRequest,
} from "@lib/projects";
import { ListItem } from "./ListItem";
import { useSourceProjects } from "@lib/sources";

const List = ({ source, ...props }: { source: string; shallow?: boolean }) => {
  const { data, isLoading, error } = useSourceProjects(source);
  const createProject = useCreateProjectRequest();
  const setEnabledStatus = useSetEnabledProjectRequest();

  return (
    <ListUI
      data={data}
      isLoading={isLoading}
      error={error}
      createProject={createProject}
      setEnabledStatus={setEnabledStatus}
      {...props}
    />
  );
};

const ListUI = ({
  data: projects,
  isLoading,
  error,
  createProject = async () => {},
  setEnabledStatus = async () => {},
  ...props
}: {
  shallow?: boolean;
  createProject?: (request: CreateProjectRequest) => Promise<void>;
  setEnabledStatus?: (id: string, enabled: boolean) => Promise<void>;
} & FetchResponse<ProjectListResponse>) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!projects?.projects) {
    return <div>No projects found</div>;
  }

  const onConfigure = async (project: Project) => {
    await createProject({
      name: project.name,
      source: project.source,
      externalId: project.externalId,
      details: project.details,
    });
  };

  return (
    <div className="project-list">
      {projects?.projects.map((project, idx) => (
        <ListItem
          key={`project-${idx}`}
          project={project}
          {...props}
          onConfigure={onConfigure}
          onEnabled={setEnabledStatus}
        />
      ))}
    </div>
  );
};

export { List };
export default ListUI;
