import { useState } from "react";
import { useOnboarding } from "@contexts/OnboardingContext";
import BaseStep from "./Base";
import { useCreateProvider } from "@lib/providers";
import { useAuthInfo } from "@propelauth/react";

const LinkProvider = () => {
  const { nextStep } = useOnboarding();
  const [awsRoleARN, setAwsRoleARN] = useState<string>("");
  const [integrationName, setIntegrationName] = useState<string>("");
  const createProvider = useCreateProvider();
  const { orgHelper } = useAuthInfo();

  function action() {
    createProvider({
      name: integrationName,
      slug: integrationName.toLowerCase().replace(" ", "-"),
      type: "AWS",
      details: {
        assumeRoleARN: awsRoleARN,
      },
      capabilities: ["Infrastructure"]
    })
      .then(nextStep)
      .catch((error) => {
        console.log(error);
      });
  }

  function handleAWSRoleARNChange(event: any) {
    setAwsRoleARN(event.currentTarget.value);
  }

  function handleIntegrationNameChange(event: any) {
    setIntegrationName(event.currentTarget.value);
  }

  function handleCloudformationInstallButton() {
    let url =
      "https://console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create/review";
    url = `${url}?stackName=SpindleAccessStack`;
    url = `${url}&templateURL=${encodeURIComponent(
      process.env.REACT_APP_CLOUDFORMATION_TEMPLATE_URL!
    )}`;
    url = `${url}&param_SpindleWorkerRole=${encodeURIComponent(
      process.env.REACT_APP_AWS_WORKER_ROLE!
    )}`;
    url = `${url}&param_OrganizationId=${encodeURIComponent(
      orgHelper?.getOrgIds()[0] ?? ""
    )}`;

    window.open(url);
  }

  return (
    <BaseStep
      title="Connect to your AWS Account"
      description="Spindle deploys your applications in your AWS account so you still have full control."
      optional={true}
      nextStep={nextStep}
      action={action}
      actionText="Connect"
      loading={false}
      optionalStep={nextStep}
    >
      <div className="mb-4">
        Spindle connects to your AWS account by assuming an IAM Role. This
        allows Spindle to deploy your applications in your AWS account without
        needing your AWS credentials. Create a role using the following
        preferred instructions and provide the IAM Role ARN below.
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Integration Name</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Integration Name"
                onChange={handleIntegrationNameChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Region</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className="select">
                <select>
                  <option value="us-east-1">US East (N. Virginia)</option>
                  <option value="us-east-2">US East (Ohio)</option>
                  <option value="us-west-1">US West (N. California)</option>
                  <option value="us-west-2">US West (Oregon)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        The easiest way to setup our integration is to use Cloudformation. Click
        the button below to create a new stack in your AWS account. Once is is
        deployed, copy the Role ARN and paste it in the field above.
      </div>

      <div className="block">
        <button
          onClick={handleCloudformationInstallButton}
          className="button is-primary"
        >
          Setup with Cloudformation
        </button>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">AWS Role ARN</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="AWS Role ARN"
                onChange={handleAWSRoleARNChange}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseStep>
  );
};

export default LinkProvider;
