import { useState } from "react";
import BaseStep from "./Base";
import { useOnboarding } from "@contexts/OnboardingContext";
import { useCreatePlatform } from "@lib/platform";
import { useCreateEnvironment } from "@lib/environments";

const CreateEnvironment = () => {
  const { nextStep } = useOnboarding();
  const [envirionmentName, setEnvironmentName] = useState<string>("");
  const createPlatform = useCreatePlatform();
  const createEnvironment = useCreateEnvironment();

  function handleEnvironmentNameChange(event: any) {
    setEnvironmentName(event.currentTarget.value);
  }

  function onSubmit(e: any) {
    e.preventDefault();

    createPlatform({ name: envirionmentName })
      .then(async (platform) => {
        await createEnvironment(platform.id, { name: envirionmentName });
      })
      .then(() => nextStep())
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <BaseStep
      title="Create your first environment"
      nextStep={nextStep}
      action={onSubmit}
      actionText="Next"
      loading={false}
    >
      <div className="field">
        <label className="label">What is the name for your new Environment?</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Environment Name"
            onChange={handleEnvironmentNameChange}
          />
        </div>
      </div>
    </BaseStep>
  );
};

export default CreateEnvironment;
