type SettingBaseProps = {
  title: string,
  id: string,
  children: React.ReactNode
}

const SettingBase = (props: SettingBaseProps) => {
  return (
    <section id={props.id} className="section">
      <h4 className="title is-4">{props.title}</h4>
      {props.children}
    </section>
  )
}

export default SettingBase;