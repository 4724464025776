import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import "./BackAction.css";

const BackAction = ({ text }: { text: string }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return <BackActionUI text={text} onClick={onClick} />;
};

const BackActionUI = ({
  onClick = () => {},
  text,
}: {
  text: string;
  onClick?: () => void;
}) => {
  return (
    <div className="back-action">
      <span className="icon back-action-icon">
        <FontAwesomeIcon icon={faArrowLeft} size="2x"/>
      </span>
      <span className="is-size-3">{text}</span>
    </div>
  );
};

export { BackAction };
export default BackActionUI;
