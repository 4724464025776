import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { Provider } from "@lib/providers";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";

export type Platform = {
  id: string;
  name: string;
  slug: string;
  numberOfEnvironments: number;
  numberOfProviders: number;
};

export type CreatePlatformRequest = {
  name: string;
}

export type PlatformProvider = {
  provider: Provider;  
}


type GetPlatformsResponse = { platforms: Platform[] };

export const usePlatforms = (): FetchResponse<GetPlatformsResponse> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return useSWR<GetPlatformsResponse>("/platforms", fetcher({
    onNotOk: () => {
      addError(`Unable to retrieve platforms, try again later`);
    },
  }));
};

export const usePlatform = (
  slug: string
): FetchResponse<Platform> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return useSWR<Platform>(`/platforms/${slug}`, fetcher({
    onNotOk: () => {
      addError(`Unable to retrieve platform ${slug}, try again later`);
    },
  }));
};

export function useCreatePlatform(): (
  platform: CreatePlatformRequest
) => Promise<Platform> {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();
  

  return (platform: CreatePlatformRequest) => fetcher({
    onNotOk: () => {
      addError(`Unable to create platform, try again later`);
    },
  })("/platforms", {
    method: "POST",
    body: JSON.stringify({ ...platform }),
  }).then((response) => {
    mutate("/platforms");
    return response;
  });
};
