import useSWR, { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { FetchResponse } from "@lib/common/response";
import { useErrors } from "@contexts/ErrorsContext";
import { Project, ProjectRule } from "./types";

export * from "./types";

export type ProjectListResponse = { projects: Project[] };
export const useProjects = (): FetchResponse<ProjectListResponse> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<ProjectListResponse>(
    "/projects",
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve projects, try again later`);
      },
    })
  );
};

export const useProject = (id: string): FetchResponse<Project> => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<Project>(
    `/projects/${id}`,
    fetcher({
      onNotOk: () => {
        addError(`Unable to retrieve project ${id}, try again later`);
      },
    })
  );
};

export type CreateProjectRequest = {
  name: string;
  source: string;
  externalId: string;
  details: { [key: string]: any };
};

export function useCreateProjectRequest(): (
  request: CreateProjectRequest
) => Promise<void> {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();

  return (request: CreateProjectRequest) =>
    fetcher({
      onNotOk: () => {
        addError(`Unable to create project, try again later`);
      },
    })(`/projects`, {
      method: "POST",
      body: JSON.stringify(request),
    }).then(() => {
      mutate(`/sources/${request.source}/projects`);
    });
}

export function useSetEnabledProjectRequest(): (
  id: string,
  enabled: boolean
) => Promise<void> {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();

  return (id: string, enabled: boolean) =>
    fetcher({
      onNotOk: () => {
        addError(
          `Unable to set enabled status for project ${id}, try again later`
        );
      },
    })(`/projects/${id}/enabled`, {
      method: "PUT",
      body: JSON.stringify({ enabled }),
    }).then((project: Project) => {
      mutate(`/sources/${project.source}/projects`);
    });
}

export function useSetProjectRulesRequest(): (
  id: string,
  rules: ProjectRule[]
) => Promise<void> {
  const { fetcher } = useGlobal();
  const { mutate } = useSWRConfig();
  const { addError } = useErrors();

  return (id: string, rules: ProjectRule[]) =>
    fetcher({
      onNotOk: () => {
        addError(`Unable to set rules for project ${id}, try again later`);
      },
    })(`/projects/${id}/rules`, {
      method: "PUT",
      body: JSON.stringify({ rules }),
    }).then((project: Project) => {
      mutate(`/sources/${project.source}/projects`);
    });
}
