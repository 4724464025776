import { useEnvironments } from "@lib/environments";
import { ProjectDetails } from "@lib/projects";
import { UseFormRegister } from "react-hook-form";

const DetailRuleAction = ({
  ruleIdx,
  idx,
  register,
}: {
  ruleIdx: number;
  idx: number;
  register?: UseFormRegister<ProjectDetails>;
}) => {
    const { data: envirionments, error, isLoading } = useEnvironments();

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">Deploy to </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <div className="select">
                <select
                    {...register?.(`rules.${ruleIdx}.actions.${idx}.environment`)}
                >
                    {envirionments?.environments.map((env) => (
                        <option key={env.id} value={env.id}>
                            {env.name}
                        </option>
                    ))}
                </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRuleAction;
