import { FetchResponse } from "@lib/common/response";
import ListItemUI from "./ListItem";
import { GetSourcesResponse, useSources } from "@lib/sources";
import { useNavigate } from "react-router-dom";

const List = () => {
  const { data, error, isLoading } = useSources();
  const navigate = useNavigate();

  const onClick = (id: string) => {
    navigate(`/settings/source/${id}`);
  };

  return (
    <ListUI data={data} error={error} isLoading={isLoading} onClick={onClick} />
  );
};

const ListUI = ({
  data,
  error,
  isLoading,
  onClick = () => {},
}: FetchResponse<GetSourcesResponse> & {
  onClick?: (id: string) => void;
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data?.sources) {
    return <div>No sources found</div>;
  }

  return (
    <div className="source-list">
      {data?.sources.map((source) => (
        <ListItemUI
          key={source.id}
          source={source}
          onClick={() => onClick(source.id)}
        />
      ))}
    </div>
  );
};

export { List };
export default ListUI;
