import { useSWRConfig } from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { useErrors } from "@contexts/ErrorsContext";

export type CreateGithubInstallationRequest = {
    code: string;
    installationId: string;
    setupAction: string;
}

export function useCreateGithubInstallation(): (request: CreateGithubInstallationRequest) => Promise<void> {
    const { fetcher } = useGlobal();
    const { mutate } = useSWRConfig();
    const { addError } = useErrors();

    return (request: CreateGithubInstallationRequest) => fetcher({
        onNotOk: () => {
          addError(`Unable to create github source, try again later`);
        },
      })(`/sources/github`, {
        method: 'POST',
        body: JSON.stringify(request),
    }).then(() => {
        mutate('/sources');
    });
}