import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateGithubInstallation } from "../../lib/sources/github";

const GithubInstallCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const createGithubInstallation = useCreateGithubInstallation();

  console.log("Github Install Callback");
  useEffect(() => {
    const code = searchParams.get("code");
    const installationId = searchParams.get("installation_id");
    const setupAction = searchParams.get("setup_action");

    const returnTo = JSON.parse(
      localStorage.getItem("githubCallbackReturnTo")!
    );
    createGithubInstallation({
      code: code || "",
      installationId: installationId || "",
      setupAction: setupAction || "",
    })
      .then(() => {
        console.log("Github Installation Success");
        navigate(returnTo.successPath);
      })
      .catch((err) => {
        console.error(err);
        navigate(returnTo.errorPath);
      });
  }, []);

  return (
    <div>
      <h1>Installing Github App</h1>
    </div>
  );
};

export default GithubInstallCallback;
