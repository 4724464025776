import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

type DropdownProps = {
  options: {
    label: string;
    value: string;
  }[];
  onSelected?: (value: string) => void;
  children?: React.ReactNode;
};

const Dropdown = (props: DropdownProps) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState("");
  const toggleActive = () => {
    setActive(!active);
  };

  const onSelected = (value: string) => {
    setActive(false);
    setSelected(value);
    if (props.onSelected) {
      props.onSelected(value);
    }
  };

  let options = props.options.map((option, index) => {
    return (
      <a
        key={index}
        className={
          option.value === selected
            ? "dropdown-item is-active"
            : "dropdown-item"
        }
        onClick={() => onSelected(option.value)}
      >
        {option.label}
      </a>
    );
  });
  let display = props.options.find((option) => option.value === selected);
  const icon = active ? faAngleLeft : faAngleDown;

  return (
    <div className={active ? "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <Button onClick={toggleActive}>
          <span>{display?.label}</span>
          <span className="icon is-small">
            <FontAwesomeIcon icon={icon} />
          </span>
        </Button>
      </div>

      <div className="dropdown-menu">
        <div className="dropdown-content">
          {options}

          {props.children && (
            <>
              <hr className="dropdown-divider" />
              {props.children}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
