import { useOnboarding } from "@contexts/OnboardingContext";
import BaseStep from "./Base";

const LinkSource = () => {
  const { nextStep, skipNext } = useOnboarding();

  function action() {
    localStorage.setItem("githubCallbackReturnTo", JSON.stringify({
      successPath: "/onboarding?step=2",
      errorPath: "/onboarding?step=1",
    }));
    window.location.href = "https://github.com/apps/spindle-platform-engineering/installations/new" 
  }

  return (
    <BaseStep
      title="Install our Github App"
      description="Spindle needs access to your Github repositories to deploy your application when you make changes"
      nextStep={nextStep}
      action={action}
      optional={true}
      actionText="Install"
      loading={false}
      optionalStep={skipNext}
    />
  );
};

export default LinkSource;
