import { useErrors } from "@contexts/ErrorsContext";
import { useGlobal } from "@contexts/GlobalContext";
import { useEffect, useState } from "react";

const LogViewer = ({
  pipelineId,
  actionId,
  ply,
}: {
  pipelineId: string;
  actionId: string;
  ply: string;
}) => {
  const { addError } = useErrors();
  const { fetcher } = useGlobal();

  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetcher({})(
          `/pipelines/${pipelineId}/${actionId}/${ply}/logs`
        );
        setLogs(response.logs);
      } catch (error) {
        addError("Unable to retrieve logs, try again later");
      }
    };

    fetchLogs();
  }, []);

  return <LogViewerUI logs={logs} />;
};

const LogViewerUI = ({ logs }: { logs: string[] }) => {
  return (
    <div
      style={{
        height: 500,
        overflow: "scroll",
        whiteSpace: "nowrap",
        background: "grey",
        color: "white",
      }}
    >
      {logs.map((log, index) => (
        <div key={index}>{log}</div>
      ))}
    </div>
  );
};

export { LogViewer };
export default LogViewerUI;
