import { useAuthInfo } from "@propelauth/react";
import { createContext, useContext } from "react";

type GlobalContent = {
  fetcher: (
    params: CreateFetcherParams
  ) => (url: string, init?: RequestInit) => Promise<any>;
};

type GlobalProps = {
  children: React.ReactNode;
};

export const GlobalContext = createContext<GlobalContent>({
  fetcher: () => () => Promise.resolve(),
});

export type CreateFetcherParams = {
  onNotOk?: (response: Response) => void;
  postOp?: (data: any) => any;
};

const GlobalProvider = ({ children }: GlobalProps) => {
  const { accessToken, orgHelper } = useAuthInfo();

  const fetcher =
    ({ onNotOk, postOp }: CreateFetcherParams) =>
    (url: string, init?: RequestInit) =>
      fetch(`${process.env.REACT_APP_API_BASE_URL??""}/api${url}`, {
        ...init,
        headers: {
          ...(init?.headers ?? {}),
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "Organization-Id": process.env.REACT_APP_FORCE_ORGANIZATION??(orgHelper?.getOrgIds()[0] ?? ""),
        },
      }).then((response) => {
        if (!response.ok) {
          if (onNotOk) {
            onNotOk(response);
          }
          throw new Error(response.statusText);
        }

        if (response.headers.get("Content-Length") === "0") {
          return;
        }

        let ret = response.json()
        if (postOp) {
          ret = ret.then(postOp)
        }

        return ret;
      });

  const context = {
    fetcher,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;

export const useGlobal = () => useContext(GlobalContext);
