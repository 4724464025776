import classNames from "classnames";

export interface ButtonProps {
  primary?: boolean;
  inverted?: boolean;
  outlined?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  size?: "small" | "normal" | "medium" | "large";
  children?: React.ReactNode;
  onClick?: (event: any) => void;
  classes?: { [key: string]: boolean };
}

const Button = ({
  primary = false,
  inverted = false,
  outlined = false,
  rounded = false,
  size = "normal",
  children,
  classes = {},
  ...props
}: ButtonProps) => {
  const buttonClasses = classNames("button", {
    "is-primary": primary,
    "is-inverted": inverted,
    "is-outlined": outlined,
    "is-rounded": rounded,
    [`is-${size}`]: size,
  }, classes);

  return (
    <button type="button" className={buttonClasses} {...props}>
      {children}
    </button>
  );
};

export default Button;
