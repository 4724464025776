import { useState } from "react";
import BaseStep from "./Base";
import { useOnboarding } from "@contexts/OnboardingContext";
import { useCreateOrganization } from "@lib/organization";

const CreateOrganization = () => {
  const { nextStep } = useOnboarding();
  const [organizationName, setOrganizationName] = useState<string>("");
  const createOrganization = useCreateOrganization();

  function handleOrganzationNameChange(event: any) {
    setOrganizationName(event.currentTarget.value);
  }

  function onSubmit(e: any) {
    e.preventDefault();

    createOrganization(organizationName).then(() => {
      nextStep();
    }).catch((error) => { 
      console.error(error);
    })
  }

  return (
    <BaseStep
      title="Welcome to Spindle!"
      nextStep={nextStep}
      action={onSubmit}
      actionText="Next"
      loading={false}
    >
      <div className="field">
        <label className="label">What is the name of your Organization?</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Organization Name"
            onChange={handleOrganzationNameChange}
          />
        </div>
      </div>
    </BaseStep>
  );
};

export default CreateOrganization;
