import { Link, useLocation } from "react-router-dom";
import Logo from "../logoFull.svg";
import { useModal } from "@contexts/ModalContext";
import BiginSupport from "@components/BiginSupportIssue";
import {
  useAuthInfo,
  useLogoutFunction,
  useRedirectFunctions,
} from "@propelauth/react";

const Navigation = () => {
  const { user } = useAuthInfo();
  const logoutFn = useLogoutFunction();
  const { pathname } = useLocation();
  const { setContent } = useModal();
  const { redirectToAccountPage, redirectToOrgPage } = useRedirectFunctions();

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img
              alt="Spindle"
              src={Logo}
              style={{
                maxHeight: "3rem",
              }}
            />
          </Link>

          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-menu">
          {/* <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Platforms</a>

              <div className="navbar-dropdown">
                {platforms?.platforms.map((platform) => (
                  <Link
                    key={platform.slug}
                    className={
                      pathname === `/platforms/${platform.slug}`
                        ? "navbar-item is-active"
                        : "navbar-item"
                    }
                    to={`/platforms/${platform.slug}`}
                  >
                    {platform.name}
                  </Link>
                ))}
                <a className="navbar-item" onClick={() => setContent(<NewPlatform />)}>
                  New Platform
                </a>
              </div>
            </div>
            <Link
              className={
                pathname === "/projects"
                  ? "navbar-item is-active"
                  : "navbar-item"
              }
              to="/projects"
            >
              Projects
            </Link>
            <Link
              className={
                pathname === "/deployments"
                  ? "navbar-item is-active"
                  : "navbar-item"
              }
              to="/deployments"
            >
              Deployments
            </Link>
          </div> */}

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                <figure className="image mr-1">
                  <img src={user!.pictureUrl} className="is-rounded" />
                </figure>
                {user!.firstName} {user!.lastName}
              </a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => redirectToAccountPage()}
                >
                  Account
                </a>
                <a className="navbar-item" onClick={() => redirectToOrgPage()}>
                  Organization
                </a>
                <Link
                  className={
                    pathname === "/settings"
                      ? "navbar-item is-active"
                      : "navbar-item"
                  }
                  to="/settings"
                >
                  Settings
                </Link>
                <hr className="navbar-divider" />
                <a
                  className="navbar-item"
                  onClick={() => setContent(<BiginSupport />)}
                >
                  Submit an issue
                </a>
                <hr className="navbar-divider" />
                <a className="navbar-item" onClick={() => logoutFn(true)}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
