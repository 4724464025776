import { useNavigate, useParams } from "react-router-dom";
import PipelineView from "@components/pipelines/View";
import { BackAction } from "@components/navigation/BackAction";

const Pipeline = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  return (
    <>
      <header className="page-header mb-2" onClick={goBack}>
        <BackAction text="Pipelines" />
      </header>
      <PipelineView id={id ?? ""} />
    </>
  );
};

export default Pipeline;
