import useSWR from "swr";
import { useGlobal } from "@contexts/GlobalContext";
import { Project } from "@lib/projects";
import { useErrors } from "@contexts/ErrorsContext";

export enum SourceType {
  GITHUB = 'Github'
}

export type Source = {
  id: string;
  name: string;
  slug: string;
  type: SourceType;
  details: { [key: string]: string };
};


export type GetSourcesResponse = { sources: Source[] } | undefined;

export const useSources = (): {
  data: GetSourcesResponse;
  error: any;
  isLoading: boolean;
} => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return useSWR<GetSourcesResponse>("/sources", fetcher({
    onNotOk: () => {
      addError(`Unable to retrieve sources, try again later`);
    },
  }));
};

export type GetSourceResponse = Source | undefined;

export const useSource = (
  id: string
): {
  data: GetSourceResponse;
  error: any;
  isLoading: boolean;
} => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<GetSourceResponse>(`/sources/${id}`, fetcher({
    onNotOk: () => {
      addError(`Unable to retrieve source ${id}, try again later`);
    },
  }));
};

export type GetSourceProjectsResponse = { projects: Project[] } | undefined;
export const useSourceProjects = (
  id: string
): {
  data: GetSourceProjectsResponse;
  error: any;
  isLoading: boolean;
} => {
  const { fetcher } = useGlobal();
  const { addError } = useErrors();

  return useSWR<GetSourceProjectsResponse>(`/sources/${id}/projects`, fetcher({
    onNotOk: () => {
      addError(`Unable to retrieve projects for ${id}, try again later`);
    },
  }));
}
