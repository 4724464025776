import Menu from "@components/ui/Menu";
import DNSSettings from '@components/settings/DNS';
import Providers from '@components/settings/Providers';
import Sources from '@components/settings/Sources';

export const settings = [{
    category: 'General',
    entries: [
      { label: 'DNS', value: 'dns', component: (<DNSSettings />) },
      { label: 'Sources', value: 'sources', component: (<Sources />) },
      { label: 'Providers', value: 'providers', component: (<Providers />) },
    ],
  }]

export default () => {
    return <Menu entries={settings} />
};