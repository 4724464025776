import React, { createContext, useContext, useEffect, useState } from "react";

type ModalContent = {
  content: React.ReactNode;
  setContent: (content: React.ReactNode) => void;
};

export const ModalContext = createContext<ModalContent>({
  content: <></>,
  setContent: () => {},
});

export const useModal = () => useContext(ModalContext);

type ModalProps = {
  children: React.ReactNode;
};

const ModalProvider = ({ children }: ModalProps) => {
  const [content, setContent] = useState<React.ReactNode>();

  const context = {
    content,
    setContent,
  };

  return (
    <ModalContext.Provider value={context}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};

export const Modal = () => {
  const { content, setContent } = useModal();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(content !== undefined);
  }, [content]);

  return (
    <div className="container is-clipped">
      <div className={show ? "modal is-active" : "modal"}>
        <div
          className="modal-background"
          onClick={() => setContent(undefined)}
        ></div>
        <div className="modal-content">
          <div className="p-2 has-background-white">{content}</div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setContent(undefined)}
        ></button>
      </div>
    </div>
  );
};

export default ModalProvider;
