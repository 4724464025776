import { Route, Routes } from "react-router-dom";
import DefaultLayout from "@pages/layouts/DefaultLayout";
import { Layout as SettingsLayout } from "@pages/layouts/SettingsLayout";
import Pipelines from "@pages/Pipelines";
import Platform from "@pages/Platform";
import Settings from "@pages/Settings";
import Onboarding from "@pages/onboarding/Onboarding";
import GithubInstallCallback from "@components/callbacks/githubInstall";
import Pipeline from "@pages/Pipeline";
import LoggedIn from "@pages/Login";
import SourcesPage from "@pages/Sources";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Pipelines />} />
        <Route path="/pipelines" element={<Pipelines />} />
        <Route path="/pipelines/:id" element={<Pipeline />} />
        <Route path="/platforms/:slug" element={<Platform />} />
      </Route>
      <Route path="/settings" element={<SettingsLayout />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/source/:id" element={<SourcesPage />} />
      </Route>
      <Route path="/onboarding" element={<Onboarding />} />
      <Route
        path="/callbacks/github/installation"
        element={<GithubInstallCallback />}
      />
      <Route path="/callbacks/logged-in" element={<LoggedIn />} />
    </Routes>
  );
};

export default Router;
