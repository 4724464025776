import CardListItemUI from "@components/ui/CardListItem";
import { ProjectDetails, ProjectRuleAction, ProjectRuleCondition } from "@lib/projects";
import { Control, FieldArrayWithId, useFieldArray } from "react-hook-form";
import DetailRuleCondition from "./DetailRuleCondition";
import DetailRuleAction from "./DetailRuleAction";
import { useState } from "react";
import Button from "@components/ui/Button";

export const DetailRule = ({
  control,
  idx,
  remove
}: {
  idx: number;
  control: Control<ProjectDetails, any>;
  remove: (idx: number) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DetailRuleUI
      idx={idx}
      control={control}
      isOpen={isOpen}
      remove={() => remove(idx)}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    />
  );
};

const DetailRuleUI = ({
  control,
  idx,
  remove = () => {},
  ...props
}: {
  idx: number;
  control: Control<ProjectDetails, any>;
  isOpen?: boolean;
  remove?: () => void;
  onClick?: () => void;
}) => {
  const { fields: conditions } = useFieldArray({
    control,
    name: `rules.${idx}.actions`,
  });

  const { fields: actions } = useFieldArray({
    control,
    name: `rules.${idx}.conditions`,
  });

  return (
    <CardListItemUI
      title={`Rule ${idx + 1}`}
      hasContent={true}
      isOpen={true}
      {...props}
    >
      {conditions.map((field, index) => (
        <DetailRuleCondition
          key={field.id}
          ruleIdx={idx}
          idx={index}
          register={control.register}
        />
      ))}
      {actions.map((field, index) => (
        <DetailRuleAction
          key={field.id}
          ruleIdx={idx}
          idx={index}
          register={control.register}
        />
      ))}

      <div className="field">
        <div className="control">
          <Button onClick={remove} classes={{ "is-danger": true }}>
            Delete
          </Button>
        </div>
      </div>
    </CardListItemUI>
  );
};

function ruleName(conditions: FieldArrayWithId<ProjectRuleCondition>, actions: ProjectRuleAction) {
  return `name here`;
}

export default DetailRuleUI;
