import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type OnboardingContent = {
  step: number;
  nextStep: () => void;
  skipNext: () => void;
};

type OnboardingProps = {
  children: React.ReactNode;
};

export const OnboardingContext = createContext<OnboardingContent>({
  step: 0,
  nextStep: () => {},
  skipNext: () => {},
});

const OnboardingProvider = ({ children }: OnboardingProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const returnStep = searchParams.get("step");
  const [step, setStep] = useState(Number.parseInt(returnStep || "0"));

  useEffect(() => {
    searchParams.set("step", step.toString());
    setSearchParams(searchParams);
  }, [step, searchParams, setSearchParams]);

  const context = {
    step,
    nextStep: () => setStep(step + 1),
    skipNext: () => setStep(step + 2),
  };

  return (
    <OnboardingContext.Provider value={context}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;

export const useOnboarding = () => useContext(OnboardingContext);
