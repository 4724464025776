import CardListItemUI, { CardListItemProps } from "@components/ui/CardListItem";
import { Project } from "@lib/projects";
import Button from "@components/ui/Button";
import { useState } from "react";
import { Details } from "./Details";

const ListItem = ({
  project,
  onConfigure = async () => {},
  onEnabled = async () => {},
  shallow = false,
  ...props
}: {
  project: Project;
  onConfigure?: (project: Project) => Promise<void>;
  onEnabled?: (id: string, enabled: boolean) => Promise<void>;
  shallow?: boolean;
} & Omit<CardListItemProps, "title">) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ListItemUI
      project={project}
      onConfigure={onConfigure}
      onEnabled={onEnabled}
      shallow={shallow}
      isOpen={isOpen}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      {...props}
    />
  );
};

const ListItemUI = ({
  project,
  onConfigure = async () => {},
  onEnabled = async () => {},
  shallow = false,
  ...props
}: {
  project: Project;
  onConfigure?: (project: Project) => Promise<void>;
  onEnabled?: (id: string, enabled: boolean) => Promise<void>;
  shallow?: boolean;
} & Omit<CardListItemProps, "title">) => {
  const isConfigured = project.id !== "00000000-0000-0000-0000-000000000000";
  const headerContent = isConfigured ? (
    <div
      onClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      <input
        type="checkbox"
        id={`${project.id}-enabled`}
        className="switch is-rounded"
        onChange={(event) => {
          onEnabled(project.id, event.target.checked);
        }}
        defaultChecked={project.enabled}
      />
      <label htmlFor={`${project.id}-enabled`} className="checkbox"></label>
    </div>
  ) : (
    <Button
      primary={true}
      size="small"
      onClick={() => onConfigure(project)}
    >
      Create Project
    </Button>
  );
  const itemClass = {
    "has-background-grey-lighter": isConfigured && !project.enabled,
  };

  return (
    <CardListItemUI
      {...props}
      title={project.name}
      hasContent={!shallow && isConfigured}
      classes={itemClass}
      headerContent={headerContent}
    >
      <Details project={project} />
    </CardListItemUI>
  );
};

export { ListItem };
export default ListItemUI;
