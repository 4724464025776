import Button from "@components/ui/Button";
import TimerUI, { Timer } from "@components/ui/Timer";
import { Pipeline } from "@lib/pipelines";
import { Project } from "@lib/projects";
import classNames from "classnames";

import "./ListItem.scss";

const ListItem = ({
  deployment,
  project,
  restart = () => Promise.resolve(),
  onClick = () => {},
}: {
  project: Project;
  deployment: Pipeline;
  restart?: (project: string, deploymentId: string) => Promise<void>;
  onClick?: () => void;
}) => {
  const restartDeployment = (event: any) => {
    event.stopPropogation();
    restart(deployment.project, deployment.id);
  };

  const displayClass = classNames("columns", "deployment-list-item", {
    "is-success": deployment.status === "Completed",
    "has-background-warning": deployment.status === "Pending",
    "is-failed": deployment.status === "Failed",
    "has-background-info": deployment.status === "InProgress",
  });

  const startTime = deployment.startedAt ?? deployment.createdAt;

  return (
    <div className={displayClass} onClick={onClick}>
      <div className="column is-align-content-center">{project.name}</div>
      <div className="column is-align-content-center">
        {deployment.details.ref}:{deployment.details.commit.substring(0, 7)}
      </div>
      <div className="column is-align-content-center">{deployment.status}</div>
      <div className="column is-align-content-center">{deployment.createdAt.toLocaleString()}</div>
      <div className="column is-align-content-center">
        {(deployment.status === "Completed" ||
          deployment.status === "Failed") && (
          <TimerUI
            timer={
              deployment.updatedAt.getTime() - startTime.getTime()
            }
          />
        )}
        {deployment.status === "InProgress" && (
          <Timer startTime={deployment.startedAt??deployment.createdAt} />
        )}
        {deployment.status === "Pending" && (
          <Timer startTime={deployment.createdAt} />
        )}
      </div>
      <div className="column is-1 is-align-content-center">
        <Button
          disabled={deployment.status !== "Failed"}
          onClick={restartDeployment}
          size="small"
          rounded={true}
        >
          Restart
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
