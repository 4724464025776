import { useEffect, useState } from "react";
import Dropdown from "@ui/Dropdown";
import { Project, useProjects } from "@lib/projects";
import { DeploymentsList } from "@components/pipelines/List";
import { useModal } from "@contexts/ModalContext";
import { useSearchParams } from "react-router-dom";

const Deployments = () => {
  const { data: projects, isLoading, error } = useProjects();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const { setContent } = useModal();

  const setProject = (value: string) => {
    setSelectedProject(
      projects!.projects.find((project) => project.id === value)
    );
  };

  useEffect(() => {
    searchParams.set("project", selectedProject?.id || "");
    setSearchParams(searchParams);
  }, [selectedProject, searchParams, setSearchParams]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Dropdown
        options={projects!.projects.map((project) => {
          return { label: project.name, value: project.id };
        })}
        onSelected={setProject}
      ></Dropdown>
      {/* <button
        className="button is-link"
        onClick={() => {
          setContent(
            <CreateDeployment project={selectedProject}></CreateDeployment>
          );
        }}
      >
        Create Deployment
      </button> */}

      {selectedProject ? (
        <DeploymentsList project={selectedProject!}></DeploymentsList>
      ) : (
        <div>No project selected</div>
      )}
    </>
  );
};

export default Deployments;
