import CardListItemUI from "@components/ui/CardListItem";
import { Source } from "@lib/sources";

const ListItemUI = ({
  source,
  onClick = () => {},
}: {
  source: Source;
  onClick?: () => void;
}) => {
  return (
    <CardListItemUI title={`${source.type} - ${source.name}`} hasContent={false} onClick={onClick} />
  );
};

export default ListItemUI;
