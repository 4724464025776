import Logo from "../../../logo.svg";

type BaseStepProps = {
  title: string;
  actionText: string;
  nextStep: (e: any) => void;
  optionalStep?: (e: any) => void;
  loading: boolean;

  description?: string;
  children?: React.ReactNode;
  optional?: boolean;
  action?: (e: any) => void;
};

const BaseStep = ({
  title,
  description,
  children,
  optional,
  nextStep,
  optionalStep,
  action,
  actionText,
  loading,
}: BaseStepProps) => {
  return (
    <div>
      <figure className="image">
        <img
          alt="Spindle Logo"
          src={Logo}
          style={{
            maxHeight: "8rem",
          }}
        />
      </figure>
      <h1 className="title">{title}</h1>
      {description && <h2 className="subtitle">{description}</h2>}
      {children}
      <div className="field">
        <div className="control">
          <button
            className="button is-primary"
            onClick={action || nextStep}
            disabled={loading}
          >
            <span>{actionText}</span>
            <span className="icon">
              <i
                className={
                  loading ? "fas fa-circle-notch fa-spin" : "fas fa-arrow-right"
                }
              ></i>
            </span>
          </button>
        </div>
      </div>
      {optional && (
        <div className="field">
          <div className="control">
            <button className="button is-text" onClick={optionalStep}>
              Not Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BaseStep;
