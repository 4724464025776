import { FetchResponse } from "@lib/common/response";
import { GetProvidersResponse, useProviders } from "@lib/providers";
import ListItemUI from "./ListItem";
import { useNavigate } from "react-router-dom";

export const List = () => {
    const { data, error, isLoading } = useProviders();
    const navigate = useNavigate();

    const onClick = (id: string) => {
        navigate(`/settings/provider/${id}`)
    }

    return (
        <ListUI data={data} error={error} isLoading={isLoading} onClick={onClick} />
    )
}

const ListUI = ({
  data,
  isLoading,
  error,
  onClick = () => {},
}: FetchResponse<GetProvidersResponse> & {
  onClick?: (id: string) => void;
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data?.providers) {
    return <div>No Providers found</div>;
  }

  return (
    <div className="source-list">
      {data?.providers.map((provider) => (
        <ListItemUI
          key={provider.id}
          provider={provider}
          onClick={() => onClick(provider.id)}
        />
      ))}
    </div>
  );
};

export default ListUI;
