import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoggedIn = () => {
  const { user, orgHelper } = useAuthInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && orgHelper.getOrgIds().length === 0) {
      navigate("/onboarding?step=0");
    } else {
      navigate("/");
    }
  }, [user, navigate, orgHelper]);

  return <>Loging In</>;
};

export default LoggedIn;
