import { useErrors } from "@contexts/ErrorsContext";
import { useGlobal } from "@contexts/GlobalContext";

export type Organization = {
  id: string;
  name: string;
}

export function useCreateOrganization(): (name: string) => Promise<void> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fetcher } = useGlobal();
  const { addError } = useErrors();
  
  return (name: string) => fetcher({
    onNotOk: () => {
      addError(`Unable to create organization, try again later`);
    },
  })("/organizations", {
    method: "POST",
    body: JSON.stringify({ name }),
  });
};
